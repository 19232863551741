import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import Form from 'form';
import dispatchRerender from 'dispatchRerender';
import { pushCandidateEvent, useDataLayer } from "dataLayerHelper";
import axios from 'axios';
import FormContext from "formContext";
import GetRouteByName from 'getRouteByName';
import SubMenu from "@Components/SubMenu";
import Icon from "@Components/Icon";
import NoticeInPage from "@Components/NoticeInPage";

function Login({intl, staticContext, location}) {
  const urlMessageParam = new URLSearchParams(location.search).get('message');
  const urlBrightfitParam = new URLSearchParams(location.search).get(process.env.REACT_APP_BRIGHTFIT_UTM_PARAMETER);

  const { locale } = intl;
  const {formData, setFormData, handleErrors, currentStep, showToast, setToastMessage, handleChange, setLoading, additionalData, setAdditionalData} = useContext(FormContext);
  const [showConfirmation, setConfirmation] = useState(urlMessageParam);
  const [redirectToBrightfit, setRedirectToBrightfit] = useState(false);
  const dashboardUrl = GetRouteByName('dashboard').url;
  const loginRouteConfig = GetRouteByName('login');
  const brightFitUrl = '/api/my-randstad/brightfit-redirect';

  const headerTitleLeft = intl.formatMessage({id: "Login.Header.Title.Left"});
  const headerTitleRight = intl.formatMessage({id: "Login.Header.Title.Right"});
  if (process.env.REACT_APP_BRIGHTFIT === "true" && process.env.REACT_APP_COUNTRY === "au" && urlBrightfitParam === 'brightfit' && typeof window !== "undefined" && redirectToBrightfit === false) {
    localStorage.setItem('redirectToBrightfit', 'true');
    setRedirectToBrightfit(true);
  }

  const noticeSettings = {
    type: 'warning',
    message: intl.formatMessage({id: "Cutover.Login"})
  }

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    // If the route have a dataFetcher but the data is missing from context.
    if (loginRouteConfig?.dataFetcher) {
      if (loginRouteConfig.dataFetcher === '@Services/getLoginPageSettings' && !additionalData?.accept_terms_conditions_privacy_policy) {
        axios.get(`${process.env.REACT_APP_API_PREFIX}/get-login-page-settings`).then(res => {
          setAdditionalData(res?.data)
        })
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#toast=logged-out") {
      setToastMessage(intl.formatMessage({id: 'Login.LoggedOut.Text'}));
      showToast();
    }
    dispatchRerender();
    setFormData([]);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    // Fetch saved jobs.
    const favoriteJobs = JSON.parse(localStorage.getItem("favJobs"));
    if (favoriteJobs && formData[currentStep]) {
      formData[currentStep].savedJobs = favoriteJobs;
    }

    if (captcha?.getToken('mrnd_captcha')) {
      if (!formData[currentStep]) {
        formData[currentStep] = {};
      }
      formData[currentStep].captchaToken = captcha?.getToken('mrnd_captcha');
    }

    axios.post(`${process.env.REACT_APP_API_PREFIX}/login`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          pushCandidateEvent("login");
          // If its UK OpCo and we have redirectToBrightfit state (which should have the localStorage variable)
          //    OR we have only the local storage variable (case where we have it from before, but we dont have the utm parameters).
          if (process.env.REACT_APP_BRIGHTFIT === "true" && process.env.REACT_APP_COUNTRY === "au" && (redirectToBrightfit || localStorage.getItem("redirectToBrightfit"))) {
            localStorage.removeItem('redirectToBrightfit');
            window.location = brightFitUrl;
          } else {
            window.location = dashboardUrl;
          }
          setLoading(false);
          captcha?.reset('mrnd_captcha');
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
        setLoading(false);
        captcha?.reset('mrnd_captcha');
      });
  };

  const renderConfirmation = () => {
    if (!showConfirmation) {
      return '';
    }
    return (
      <div
        className={`block notice-confirmed closable bg-variant-brand-quaternary notification-type-${showConfirmation}`}
        data-rs-hours-confirmed-notification-1=""
      >
        <div className="wrapper notice-confirmed__wrapper">
          <div className="notice-confirmed__media" data-rs-closable-fadeout="">
            {showConfirmation === "confirmed" && <img src='/myrandstad-app/assets/image/icons/illustration-hand-1a.svg' alt=""/>}
            {showConfirmation === "reset" && <img src='/myrandstad-app/assets/image/icons/illustration-medal.svg' alt=""/>}
          </div>
          <div className="notice-confirmed__content">
            <div className="notice-confirmed__text" data-rs-closable-fadeout="">
              {showConfirmation === "confirmed" && <p>{intl.formatMessage({id: 'Login.Message.AccountWasVerified'})}</p>}
              {showConfirmation === "reset" && <p>{intl.formatMessage({id: 'Login.Message.ResetPasswordSuccessful'})}</p>}
            </div>
            <div className="notice-confirmed__close">
              <button className="button--icon-only" onClick={() => setConfirmation('')} aria-label={intl.formatMessage({ id: "Close" })}>
                <Icon type="close-30" className="icon icon--inline" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Login'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <SubMenu />
          <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{headerTitleLeft}</span>
              <span className="content-block__title-bottom text--emphasis">{headerTitleRight}</span>
            </h1>
          </div>
        </div>
      </div>
      { process.env.REACT_APP_COUNTRY === "it" &&
        <div className="block block--xs">
          <div className="wrapper block__wrapper">
            <NoticeInPage settings={noticeSettings} style={{maxWidth: '100%'}} />
          </div>
        </div>
      }
      {renderConfirmation()}
      <Form name='login' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} />
    </>
  )
}

export default injectIntl(Login);
